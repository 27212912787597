<template>
  <v-container
    class="mt-5"
    style="min-height: 500px"
  >
    <PagePreLoader
      :loading="loading"
    />

    <v-row>
      <!--search bar-->
      <v-col
        cols="12"
        md="8"
        offset-md="2"
        class="mb-4"
      >
        <form @submit.prevent="submitHandler">
          <v-text-field
            v-model="searchText"
            class="search-field"
            placeholder="Search for Shops"
            color="secondary"
            :clearable="true"
            outlined
            hide-details
            @click:clear="fetchVendors('')"
            @input="fetchVendorsByName"
          >
            <template #append-outer>
              <v-btn
                class="search-btn"
                color="secondary"
                elevation="0"
                type="submit"
                large
              >
                <v-icon size="35">
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </form>
      </v-col>
    </v-row>

    <v-row v-if="hasData">
      <!--vendors-->
      <v-col
        v-for="(item, index) in vendors"
        :key="'vendor'+index"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <vendor-simple
          :address="item.address"
          :email="item.email"
          :link="{name: 'Vendor', params: {slug: item.slug}}"
          :name="item.name"
          :phone="item.cell"
          :cover-photo="item?.banner?.url"
          :logo="item?.logo?.url"
        />
      </v-col>

      <!--pagination-->
      <v-col cols="12">
        <v-row>
          <v-col
            v-if="pageLength"
            cols="12"
            md="6"
            offset-md="3"
            lg="4"
            offset-lg="4"
          >
            <v-card outlined>
              <div
                class="text-center align-content-center justify-center pa-3"
              >
                <v-pagination
                  v-model="pageIndex"
                  :length="pageLength"
                  circle
                  color="secondary"
                  @input="fetchVendors"
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-else-if="!loading">
      <v-col cols="12">
        <v-sheet>
          <v-card-text class="text-center text-h6">
            No Shop found{{ (searchText || '').length ? ` for "${searchText}"` : '' }}
          </v-card-text>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VendorSimple from '@/components/Vendors/VendorSimple';
import { createNamespacedHelpers } from 'vuex';
import PagePreLoader from '@/components/Utils/PagePreLoader';
import { debounce } from 'lodash';

const { mapGetters, mapActions } = createNamespacedHelpers('vendor');
export default {
  name: 'Vendors',
  components: { PagePreLoader, VendorSimple },

  data: () => ({
    searchText: '',
    pageIndex: 1
  }),

  computed: {
    ...mapGetters({
      vendors: 'data',
      hasData: 'hasData',
      loading: 'loading'
    }),

    pageLength () {
      return this.$store.state.vendor.data.total;
    }
  },

  mounted() {
    // Check if store is empty then load Data
    if (!this.hasData || this.pageIndex === 1) {
      this.fetchVendors();
    }
  },

  beforeDestroy() {
    this.pageIndex = 1;
  },

  methods: {
    ...mapActions({
      getVendorData: 'getVendorData',
    }),

    submitHandler() {
      // this.fetchVendors();
      this.fetchVendorsByName();
    },

    fetchVendors() {
      this.getVendorData({
        searchText: this.searchText ?? '',
        pageIndex: this.pageIndex
      });
    },

    resetPageIndex() {
      this.pageIndex = 1;
    },

    fetchVendorsByName: debounce(async function () {
      this.resetPageIndex();

      await this.getVendorData({
        searchText: this.searchText,
        pageIndex: this.pageIndex,
      });
    }, 350),
  },

};
</script>

<style lang="scss">
.search-field {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0 !important;
  background-color: #fff;
}

.search-btn {
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding: 28px !important;
}

.search-field {
  .v-input__append-inner {
    .v-input__icon.v-input__icon--clear {
      position: absolute;
      right: 72px;
      top: 16px;
    }
  }
}

::v-deep .v-input__append-outer {
  margin: 0;
}
.v-progress-circular {
  position: initial !important;
}
</style>

