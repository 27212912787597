<template>
  <div class="vendor-card">
    <div class="vendor-card-cover">
      <router-link :to="link">
        <v-img
          :alt="name"
          :src="coverPhoto"
          max-height="140"
          @error="useDefaultCover"
        />
      </router-link>
    </div>

    <div class="vendor-card-image">
      <router-link :to="link">
        <img
          :alt="name"
          :src="logo"
          @error="useDefaultLogo"
        >
      </router-link>
    </div>

    <div class="vendor-card-details">
      <h3>
        <router-link
          :to="link"
          class="mb-2"
        >
          <span class="text-uppercase text-decoration-none text-center vendor-name">
            {{ name }}
          </span>
        </router-link>
      </h3>

      <div class="vendor-contact-info">
        <p
          v-if="address"
          class="vendor-address"
        >
          {{ address }}
        </p>

        <p v-if="email">
          <span class="font-weight-medium">Email</span>:&nbsp;
          <a
            :href="'mailto:' + email"
            class="text-black"
          >
            <span>
              {{ email }}
            </span>
          </a>
        </p>

        <p v-if="phone">
          <span class="font-weight-medium">Phone</span>:&nbsp;
          <a :href="'tel:' + phone">
            <span class="text-black">
              {{ phone }}
            </span>
          </a>
        </p>
      </div>
    </div>

    <div class="vendor-card-action">
      <router-link :to="link">
        <v-btn
          color="secondary"
          outlined
          block
        >
          Visit Store
        </v-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
import emptyShopLogo from '@/assets/images/emptyShopLogo.jpg';
import emptyShopCover from '@/assets/images/emptyShopCover.png';

export default {
  name: 'VendorSimple',
  props: {
    coverPhoto: {
      type: String,
      default: emptyShopCover,
    },
    logo: {
      type: String,
      default: emptyShopLogo,
    },
    name: {
      type: String,
    },
    address: {
      type: String,
    },
    email: {
      type: String
    },
    phone: {
      type: String,
    },
    link: {
      type: [Object, String]
    }
  },

  methods: {
    useDefaultLogo() {
      return emptyShopLogo;
    },

    useDefaultCover() {
      return emptyShopCover;
    }
  }
};
</script>

<style lang="scss" scoped>
.vendor-card {
  min-height: 420px;

  background-color: darken(#fff, 0.5%);
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 20px;
  border-radius: 4px;
  overflow: hidden;

  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  transition: all 0.25s ease-in-out;

  &:hover {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }

  &-image {
    img {
      width: 100px;
      height: 100px;
      background-color: transparent;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: -50px;
      position: relative;

      border-radius: 50%;
      box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    }
  }

  &-details {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 10px 15px 0;

    .vendor-name {
      font-size: clamp(1.125rem, 0.11vw + 1.101rem, 1.25rem);
      line-height: clamp(1.625rem, -0.06vw + 1.7rem, 1.688rem);
      font-weight: 600;
      display: block;
      margin-bottom: 10px;
      color: var(--v-secondary-base, var(--primary-color));
    }

    p {
      margin-bottom: 10px;
    }

  }

  &-action {
    padding: 0 15px;
    margin-top: auto;
  }

  .text-black {
    color: var(--text-color);
    letter-spacing: 0.5px;
  }
}
</style>
